import { createApp } from 'vue'
import App from './App.vue'
import './assets/less/index.less'
import { startMock } from '@/mock'
import router from './router'
import mixin from './utils/mixin'
import VueLazyload from '@jambonn/vue-lazyload'
import { createPinia } from 'pinia'
import { useClick } from '@/utils/hooks/useClick'
import bus, { EVENT_KEY } from '@/utils/bus'
import { VueFire, VueFireFirestoreOptionsAPI } from 'vuefire'
import { authFire, firebaseApp } from './firebaseConfig'
import './vue-qrcode-reader.css'

import {
  Tab,
  Tabs,
  Swipe,
  SwipeItem,
  Collapse,
  CollapseItem,
  Grid,
  GridItem,
  Col,
  Row,
  Toast,
  Popup,
  Space,
  List,
  Button,
  Icon,
  Form,
  Field,
  CellGroup,
  Notify,
  Card,
  Divider,
  Switch,
  Dialog,
  DropdownMenu,
  PasswordInput,
  NumberKeyboard,
  DropdownItem,
  Checkbox,
  CheckboxGroup,
  Picker,
  ShareSheet,
  Pagination,
  Empty,
  Cell,
  NavBar,
  Loading,
  Radio,
  RadioGroup
} from 'vant'
import 'vant/lib/index.css'
import { onAuthStateChanged } from 'firebase/auth'
import { VueQrcodeReader } from 'vue-qrcode-reader'
window.isMoved = false
window.isMuted = true
window.showMutedNotice = true
HTMLElement.prototype.addEventListener = new Proxy(HTMLElement.prototype.addEventListener, {
  apply(target, ctx, args) {
    const eventName = args[0]
    const listener = args[1]
    if (listener instanceof Function && eventName === 'click') {
      args[1] = new Proxy(listener, {
        apply(target1, ctx1, args1) {
          // console.log('e', args1)
          // console.log('click点击', window.isMoved)
          if (window.isMoved) return
          try {
            return target1.apply(ctx1, args1)
          } catch (e) {
            console.error(`[proxyPlayerEvent][${eventName}]`, listener, e)
          }
        }
      })
    }
    return target.apply(ctx, args)
  }
})

const vClick = useClick()
const pinia = createPinia()
const app = createApp(App)
app.mixin(mixin)
const loadImage = new URL('./assets/img/icon/img-loading.png', import.meta.url).href
app.use(VueLazyload, {
  preLoad: 1.3,
  loading: loadImage,
  attempt: 1
})

app.use(pinia)
app.use(router)
app.use(VueFire, {
  firebaseApp,
  modules: [VueFireFirestoreOptionsAPI()]
})
app.mount('#app')
app.directive('click', vClick)
// import vant
app.use(Tab)
app.use(Tabs)
app.use(Swipe)
app.use(SwipeItem)
app.use(Collapse)
app.use(CollapseItem)
app.use(Grid)
app.use(GridItem)
app.use(Col)
app.use(Row)
app.use(Toast)
app.use(Popup)
app.use(Space)
app.use(List)
app.use(Button)
app.use(Icon)
app.use(Form)
app.use(Field)
app.use(CellGroup)
app.use(Notify)
app.use(Card)
app.use(Divider)
app.use(Switch)
app.use(Dialog)
app.use(DropdownMenu)
app.use(DropdownItem)
app.use(PasswordInput)
app.use(NumberKeyboard)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(Picker)
app.use(ShareSheet)
app.use(Pagination)
app.use(Empty)
app.use(Cell)
app.use(NavBar)
app.use(Loading)
app.use(VueQrcodeReader)
app.use(Radio)
app.use(RadioGroup)
//放到最后才可以使用pinia
startMock()
setTimeout(() => {
  bus.emit(EVENT_KEY.HIDE_MUTED_NOTICE)
  window.showMutedNotice = false
}, 2000)
bus.on(EVENT_KEY.REMOVE_MUTED, () => {
  window.isMuted = false
})

onAuthStateChanged(authFire, (user) => {
  // if (user) {
  //   localStorage.setItem(AUTHENS.AUTHEN_DATA, JSON.stringify(user))
  //   console.log('authen còn hiệu lực', user)
  // } else {
  //   localStorage.removeItem(AUTHENS.AUTHEN_DATA)
  // }
})
