<template>
  <div
    :class="[
      'app-container',
      { 'full-screen': isFullScreenRoute },
      { 'no-mobile': !isMobile },
      { 'app-mobile': isMobile }
    ]"
  >
    <router-view> </router-view>
    <Chat
      page-id="home-index"
      :video-id="null"
      :project-id="null"
      v-model="state.chatVisible"
      @close="closeChats"
    />
    <!-- <router-view v-slot="{ Component }">
      <transition :name="transitionName">
        <keep-alive :exclude="store.excludeNames">
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view> -->
    <Call />
  </div>
</template>
<script setup lang="ts">
/*
* try {navigator.control.gesture(false);} catch (e) {} //UC浏览器关闭默认手势事件
try {navigator.control.longpressMenu(false);} catch (e) {} //关闭长按弹出菜单
* */
import routes from './router/routes'
import Call from './components/Call.vue'
import { useBaseStore } from '@/store/pinia.js'
import { onMounted, ref, watch, computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import bus, { EVENT_KEY } from './utils/bus'
import Chat from './components/Chat.vue'
import { getFollowsByUserId, getProfileById, setFollow } from './api/ss'
import { AUTHENS } from './commons/constants'
import type { UserDataModel } from './models/user-data.model'
import { getUserData } from './utils'
import { showFailToast, showSuccessToast } from 'vant'
const store = useBaseStore()
const route = useRoute()
const state = reactive({
  chatVisible: false
})
const checkIfMobile = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const mobileDevices =
    /(android|iphone|ipad|ipod|blackberry|bb|playbook|windows phone|webos|opera mini|opera mobi|palm os|palm|hiptop|avantgo|blazer|iemobile|midp|up\.browser|up\.link|docomo|samsung|symbian|sony|nokia|windows ce|phone|tablet|kindle|silk|mobile|pda|psp)/i
  return mobileDevices.test(userAgent)
}
const isMobile = checkIfMobile()
const transitionName = ref('go')
// Sử dụng computed để xác định xem route hiện tại có nên full screen hay không
const isFullScreenRoute = computed(() => route.meta.fullScreen || false)
// watch $route 决定使用哪种过渡

function closeChats() {
  bus.emit(EVENT_KEY.CLOSE_CHATS)
}

watch(
  () => route.path,
  (to, from) => {
    store.setMaskDialog({ state: false, mode: store.maskDialogMode })
    //底部tab的按钮，跳转是不需要用动画的
    let noAnimation = [
      '/',
      '/home',
      '/slide',
      '/me',
      '/shop',
      '/message',
      '/publish',
      '/home/live',
      'slide',
      '/test'
    ]
    if (noAnimation.indexOf(from) !== -1 && noAnimation.indexOf(to) !== -1) {
      return (transitionName.value = '')
    }
    const toDepth = routes.findIndex((v: RouteRecordRaw) => v.path === to)
    const fromDepth = routes.findIndex((v: RouteRecordRaw) => v.path === from)
    transitionName.value = toDepth > fromDepth ? 'go' : 'back'
  }
)

function resetVhAndPx() {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  //document.documentElement.style.fontSize = document.documentElement.clientWidth / 375 + 'px'
}

function handleGetFollow() {
  const userData: UserDataModel | null = getUserData()
  if (userData) {
    getFollowsByUserId()
      .then((response) => {
        if (response.success) {
          store.setFollowers(response.data)
        } else {
          store.setFollowers([])
        }
      })
      .catch((error) => {
        console.log('getFollowById error:', error)
        store.setFollowers([])
      })
  }
}

async function handleSetFollow(data) {
  setFollow(data)
    .then((response) => {
      if (response.success) {
        showSuccessToast(`Theo dõi ${data?.userNameFollow} thành công!`)
        handleGetFollow()
      } else {
        showFailToast(`Theo dõi ${data?.userNameFollow} thất bại`)
      }
    })
    .catch((error) => {
      showFailToast(`Theo dõi ${data?.userNameFollow} thất bại`)
      console.error(error)
    })
}

onMounted(async () => {
  if (isMobile) {
    // const themeLink = document.getElementById('app-theme-chat') as HTMLLinkElement
    // if (themeLink) {
    //   console.log(themeLink.href)
    //   themeLink.href = `/chat/custom.css`
    // }
    // document.body.classList.add('mobile-body')
  }
  // Lần đầu vào web kiểm tra luôn xem login chưa, nếu login rồi thì lấy thông tin người dùng
  const user: UserDataModel | null = getUserData()
  if (user) {
    const userInfoResponse = await getProfileById()
    if (userInfoResponse?.success) {
      localStorage.setItem(AUTHENS.AUTHEN_DATA, JSON.stringify(userInfoResponse.data))
    }
  }
  store.init()
  resetVhAndPx()
  handleGetFollow()
  // 监听resize事件 视图大小发生变化就重新计算1vh的值
  window.addEventListener('resize', () => {
    // location.href = BASE_URL + '/'
    resetVhAndPx()
  })
  bus.on(EVENT_KEY.OPEN_CHATS, () => {
    // if (!state.active) return
    bus.emit(EVENT_KEY.ENTER_FULLSCREEN)
    state.chatVisible = true
  })
  bus.on(EVENT_KEY.CLOSE_CHATS, () => {
    // if (!state.active) return
    bus.emit(EVENT_KEY.EXIT_FULLSCREEN)
    state.chatVisible = false
  })
  bus.on(EVENT_KEY.GET_FOLLOW, handleGetFollow)
  bus.on(EVENT_KEY.SET_FOLLOW, handleSetFollow)
})
</script>

<style lang="less">
@import './assets/less/index';

* {
  user-select: none;
}

// #app {
//   height: 100%;
//   width: 100%;
//   position: relative;
//   font-size: 14rem;
// }

.mobile-body {
  background-color: #ffffff;
}
.app-container {
  height: 100%;
  width: 100%;
  position: relative;
  font-size: 14rem;

  &.full-screen {
    width: 100%;
  }
}

@media screen and (min-width: 500px) {
  // #app {
  //   width: 500px !important;
  //   position: relative;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
  .app-container {
    width: 500px !important;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    &.full-screen {
      width: 100% !important;
      left: 0;
      transform: none;
    }
  }
}

.go-enter-from {
  transform: translate3d(100%, 0, 0);
}

//最终状态
.back-enter-to,
.back-enter-from,
.go-enter-to,
.go-leave-from {
  transform: translate3d(0, 0, 0);
}

.go-leave-to {
  transform: translate3d(-100%, 0, 0);
}

.go-enter-active,
.go-leave-active,
.back-enter-active,
.back-leave-active {
  transition: all 0.3s;
}

.back-enter-from {
  transform: translate3d(-100%, 0, 0);
}

.back-leave-to {
  transform: translate3d(100%, 0, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
