<template>
  <div :class="positionName" class="in-video">
    <Loading v-if="state.loading" style="position: absolute" />
    <!--    <video :src="item.video + '?v=123'"-->

    <video
      :src="link"
      :index="index"
      :poster="posterlink"
      ref="videoEl"
      controls
      :muted="state.isMuted"
      x5-video-player-type="h5-page"
      :x5-video-player-fullscreen="false"
      :webkit-playsinline="true"
      :x5-playsinline="true"
      playsinline
      @canplay="onCanPlay"
      :fullscreen="false"
      :autoplay="isPlay"
    >
      <!--        <p>您的浏览器不支持 video 标签。</p>-->
    </video>
    <div class="float">
      <div
        class="progress"
        :class="progressClass"
        ref="progressEl"
        @click="null"
        @touchstart="touchstart"
        @touchmove="touchmove"
        @touchend="touchend"
      >
        <div class="time" v-if="state.isMove">
          <span class="currentTime">{{ _duration(state.currentTime) }}</span>
          <span class="duration"> / {{ _duration(state.duration) }}</span>
        </div>
        <template v-if="state.duration > 15 || state.isMove || !isPlaying">
          <div class="bg"></div>
          <div class="progress-line" :style="durationStyle"></div>
          <div class="point"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { _duration, _stopPropagation } from '@/utils'
import Loading from '../Loading.vue'
import bus, { EVENT_KEY } from '../../utils/bus'
import { SlideItemPlayStatus } from '@/utils/const_var'
import { computed, onMounted, onUnmounted, provide, reactive, watch } from 'vue'
import { _css } from '@/utils/dom'
defineOptions({
  name: 'BaseVideoCustom'
})

const props = defineProps({
  item: {
    type: Object,
    default: () => {
      return {}
    }
  },
  position: {
    type: Object,
    default: () => {
      return {}
    }
  },
  //用于第一条数据，自动播放，如果都用事件去触发播放，有延迟
  isPlay: {
    type: Boolean,
    default: () => {
      return true
    }
  },
  isMy: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  isLive: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  link: {
    type: String,
    default: () => {
      return ''
    }
  },
  posterlink: {
    type: String,
    default: () => {
      return ''
    }
  },
  index: {
    type: Number
  },
  id: {
    type: String
  },
  active: {
    type: Number,
    default: 0
  }
})

provide(
  'isPlaying',
  computed(() => isPlaying.value)
)
provide(
  'isMuted',
  computed(() => state.isMuted)
)
provide(
  'position',
  computed(() => props.position)
)
provide(
  'item',
  computed(() => props.item)
)
const videoEl = _ref<HTMLVideoElement>()
const progressEl = _ref<HTMLDivElement>()
let state = reactive({
  project_id: '',
  index: -1,
  loading: false,
  paused: false,
  isMuted: window.isMuted,
  status: props.isPlay ? SlideItemPlayStatus.Play : SlideItemPlayStatus.Pause,
  duration: 0,
  step: 0,
  currentTime: -1,
  playX: 0,
  start: { x: 0 },
  last: { x: 0, time: 0 },
  height: 0,
  width: 0,
  isMove: false,
  ignoreWaiting: false, //忽略waiting事件。因为改变进度会触发waiting事件，烦的一批
  test: [1, 2],
  localItem: props.item,
  progressBarRect: {
    height: 0,
    width: 0
  },
  videoScreenHeight: 0,
  commentVisible: false
})

const durationStyle = _computed(() => {
  return { width: state.playX + 'px' }
})
const isPlaying = _computed(() => {
  return state.status === SlideItemPlayStatus.Play
})
const positionName = _computed(() => {
  return `tvc-list-${props.index}`
})
const progressClass = _computed(() => {
  if (state.isMove) {
    return 'move'
  } else {
    return isPlaying.value ? '' : 'stop'
  }
})

const onCanPlay = () => {
  state.loading = false
}

watch(
  () => props.active,
  (newVal) => {
    bus.emit(EVENT_KEY.PAUSE_ALL_WITHOUT_ID, -1)
  },
  { immediate: true }
)

onMounted(() => {
  state.loading = true
  state.height = document.body.clientHeight
  state.width = document.body.clientWidth
  videoEl.value.currentTime = 0
  let fun = (e) => {
    state.currentTime = Math.ceil(e.target.currentTime)
    state.playX = (state.currentTime - 1) * state.step
    if (state.loading) state.loading = false
  }
  videoEl.value.addEventListener('loadedmetadata', () => {
    state.videoScreenHeight = videoEl.value.videoHeight / (videoEl.value.videoWidth / state.width)
    state.duration = videoEl.value.duration
    state.progressBarRect = progressEl.value.getBoundingClientRect()
    state.step = state.progressBarRect.width / Math.floor(state.duration)
    state.loading = false
    videoEl.value.addEventListener('timeupdate', fun)
  })
  videoEl.value.addEventListener('play', (event) => {
    bus.emit(EVENT_KEY.PAUSE_ALL_WITHOUT_ID, props.index)
  })
  videoEl.value.addEventListener('paused', () => {
    // console.log('paused')
  })
  let eventTester = (e, t: string) => {
    videoEl.value.addEventListener(
      e,
      () => {
        state.loading = true
        // console.log('eventTester', e, state.item.aweme_id)
        // if (e === 'playing') state.loading = false
        if (e === 'waiting') {
          if (!state.paused && !state.ignoreWaiting) {
            state.loading = true
          }
        }
        let s = false
        if (s) {
          console.log(e, t)
        }
      },
      false
    )
  }

  // eventTester("loadstart", '客户端开始请求数据'); //客户端开始请求数据
  // eventTester("abort", '客户端主动终止下载（不是因为错误引起）'); //客户端主动终止下载（不是因为错误引起）
  // eventTester("loadstart", '客户端开始请求数据'); //客户端开始请求数据
  // eventTester("progress", '客户端正在请求数据'); //客户端正在请求数据
  // // eventTester("suspend", '延迟下载'); //延迟下载
  // eventTester("abort", '客户端主动终止下载（不是因为错误引起），'); //客户端主动终止下载（不是因为错误引起），
  // eventTester("error", '请求数据时遇到错误'); //请求数据时遇到错误
  // eventTester("stalled", '网速失速'); //网速失速
  // eventTester("play", 'play()和autoplay开始播放时触发'); //play()和autoplay开始播放时触发
  // eventTester("pause", 'pause()触发'); //pause()触发
  // eventTester("loadedmetadata", '成功获取资源长度'); //成功获取资源长度
  // eventTester("loadeddata"); //
  eventTester('waiting', '等待数据，并非错误') //等待数据，并非错误
  eventTester('playing', '开始回放') //开始回放
  // eventTester("canplay", '/可以播放，但中途可能因为加载而暂停'); //可以播放，但中途可能因为加载而暂停
  // eventTester("canplaythrough", '可以播放，歌曲全部加载完毕'); //可以播放，歌曲全部加载完毕
  // eventTester("seeking", '寻找中'); //寻找中
  // eventTester("seeked", '寻找完毕'); //寻找完毕
  // // eventTester("timeupdate",'播放时间改变'); //播放时间改变
  // eventTester("ended", '播放结束'); //播放结束
  // eventTester("ratechange", '播放速率改变'); //播放速率改变
  // eventTester("durationchange", '资源长度改变'); //资源长度改变
  // eventTester("volumechange", '音量改变'); //音量改变

  // console.log('mounted')
  // bus.off('singleClickBroadcast')
  bus.on(EVENT_KEY.SINGLE_CLICK_BROADCAST, click)
  bus.on(EVENT_KEY.DIALOG_MOVE, onDialogMove)
  bus.on(EVENT_KEY.DIALOG_END, onDialogEnd)
  bus.on(EVENT_KEY.OPEN_COMMENTS, onOpenComments)
  bus.on(EVENT_KEY.CLOSE_COMMENTS, onCloseComments)
  bus.on(EVENT_KEY.OPEN_SUB_TYPE, onOpenSubType)
  bus.on(EVENT_KEY.CLOSE_SUB_TYPE, onCloseSubType)

  bus.on(EVENT_KEY.REMOVE_MUTED, removeMuted)
  bus.on(EVENT_KEY.PAUSE_ALL_WITHOUT_ID, pauseAllWithoutId)
})

onUnmounted(() => {
  // Reset element video
  // if (videoEl) {
  //   videoEl.src = ''
  //   videoEl.load()
  // }
  bus.off(EVENT_KEY.SINGLE_CLICK_BROADCAST, click)
  bus.off(EVENT_KEY.DIALOG_MOVE, onDialogMove)
  bus.off(EVENT_KEY.DIALOG_END, onDialogEnd)
  bus.off(EVENT_KEY.OPEN_COMMENTS, onOpenComments)
  bus.off(EVENT_KEY.CLOSE_COMMENTS, onCloseComments)
  bus.off(EVENT_KEY.OPEN_SUB_TYPE, onOpenSubType)
  bus.off(EVENT_KEY.CLOSE_SUB_TYPE, onCloseSubType)
  bus.off(EVENT_KEY.REMOVE_MUTED, removeMuted)
  bus.off(EVENT_KEY.PAUSE_ALL_WITHOUT_ID, pauseAllWithoutId)
})

function removeMuted() {
  state.isMuted = false
}

function pauseAllWithoutId(index) {
  if (props.index !== index) {
    if (videoEl.value) videoEl.value.pause()
  }
}

function onOpenSubType() {
  state.commentVisible = true
}

function onCloseSubType() {
  state.commentVisible = false
}

function onDialogMove({ tag, e }) {
  if (state.commentVisible && tag === 'comment') {
    _css(videoEl.value, 'transition-duration', `0ms`)
    _css(videoEl.value, 'height', `calc(var(--vh, 1vh) * 30 + ${e}px)`)
  }
}

function onDialogEnd({ tag, isClose }) {
  if (state.commentVisible && tag === 'comment') {
    console.log('isClose', isClose)
    _css(videoEl.value, 'transition-duration', `300ms`)
    if (isClose) {
      state.commentVisible = false
      _css(videoEl.value, 'height', '100%')
    } else {
      _css(videoEl.value, 'height', 'calc(var(--vh, 1vh) * 30)')
    }
  }
}

function onOpenComments(id) {
  if (id === props.item.aweme_id) {
    _css(videoEl.value, 'transition-duration', `300ms`)
    _css(videoEl.value, 'height', 'calc(var(--vh, 1vh) * 30)')
    state.commentVisible = true
  }
}

function onCloseComments() {
  if (state.commentVisible) {
    _css(videoEl.value, 'transition-duration', `300ms`)
    _css(videoEl.value, 'height', '100%')
    state.commentVisible = false
  }
}

function click({ uniqueId, index, type, id = '' }) {
  state.project_id = id
  state.index = index
  if (props.position.uniqueId === uniqueId && props.position.index === index) {
    // if (type === EVENT_KEY.ITEM_TOGGLE) {
    //   if (props.isLive) {
    //     pause()
    //     bus.emit(EVENT_KEY.NAV, {
    //       path: '/home/live',
    //       query: { id: props.item.aweme_id }
    //     })
    //   } else {
    //     if (state.status === SlideItemPlayStatus.Play) {
    //       pause()
    //     } else {
    //       play()
    //     }
    //   }
    // }
    if (type === EVENT_KEY.ITEM_STOP) {
      videoEl.value.currentTime = 0
      state.ignoreWaiting = true
      pause()
      setTimeout(() => (state.ignoreWaiting = false), 300)
    }
    if (type === EVENT_KEY.ITEM_PLAY) {
      videoEl.value.currentTime = 0
      state.ignoreWaiting = true
      play()
      setTimeout(() => (state.ignoreWaiting = false), 300)
    }
  }
}

function play() {
  state.status = SlideItemPlayStatus.Play
  videoEl.value.volume = 1
  videoEl.value.play()
}

function pause() {
  state.status = SlideItemPlayStatus.Pause
  videoEl.value.pause()
  console.log('pause')
}

function touchstart(e) {
  _stopPropagation(e)
  state.start.x = e.touches[0].pageX
  state.last.x = state.playX
  state.last.time = state.currentTime
}

function touchmove(e) {
  // console.log('move',e)
  _stopPropagation(e)
  state.isMove = true
  pause()
  let dx = e.touches[0].pageX - state.start.x
  state.playX = state.last.x + dx
  state.currentTime = state.last.time + Math.ceil(Math.ceil(dx) / state.step)
  if (state.currentTime <= 0) state.currentTime = 0
  if (state.currentTime >= state.duration) state.currentTime = state.duration
}

function touchend(e) {
  // console.log('end', e)
  _stopPropagation(e)
  if (isPlaying.value) return
  setTimeout(() => (state.isMove = false), 1000)
  videoEl.value.currentTime = state.currentTime
  play()
}
</script>

<style scoped lang="less">
.video-wrapper {
  position: relative;
  font-size: 14rem;
  width: 100%;
  height: 100%;
  text-align: center;

  video {
    max-width: 100%;
    height: 100%;
    transition:
      height,
      margin-top 0.3s;
    //background: black;
    /*position: absolute;*/
  }

  .float {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    .normal {
      position: absolute;
      bottom: 0;
      width: 100%;
      transition: all 0.3s;

      .comment-status {
        display: flex;
        align-items: center;

        .comment {
          .type-comment {
            display: flex;
            background: rgb(130, 21, 44);
            border-radius: 50px;
            padding: 3px;
            margin-bottom: 20px;

            .avatar {
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }

            .right {
              margin: 0 10px;
              color: var(--second-text-color);

              .name {
                margin-right: 10px;
              }

              .text {
                color: white;
              }
            }
          }

          .loveds {
          }

          .type-loved {
            width: 40px;
            height: 40px;
            position: relative;
            margin-bottom: 20px;
            animation: test 1s;
            animation-delay: 0.5s;

            .avatar {
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }

            .loved {
              position: absolute;
              bottom: 0;
              left: 20px;
              width: 10px;
              height: 10px;
              background: red;
              padding: 3px;
              border-radius: 50%;
              border: 2px solid white;
            }
          }

          @keyframes test {
            from {
              display: block;
              transform: translate3d(0, 0, 0);
            }
            to {
              display: none;
              transform: translate3d(0, -60px, 0);
            }
          }
        }
      }
    }

    .progress {
      z-index: 10;
      @w: 90%;
      position: absolute;
      bottom: -1rem;
      height: 10rem;
      left: calc((100% - @w) / 2);
      width: @w;
      display: flex;
      align-items: flex-end;
      margin-bottom: 2rem;

      .time {
        position: absolute;
        z-index: 9;
        font-size: 24px;
        bottom: 50px;
        left: 0;
        right: 0;
        color: white;
        text-align: center;

        .duration {
          color: darkgray;
        }
      }

      @radius: 10rem;

      @h: 2rem;
      @tr: height 0.3s;

      .bg {
        transition: @tr;
        position: absolute;
        width: 100%;
        height: @h;
        background: #4f4f4f;
        border-radius: @radius;
      }

      @p: 50px;

      .progress-line {
        transition: @tr;
        height: calc(@h + 0.5rem);
        width: @p;
        border-radius: @radius 0 0 @radius;
        background: #777777;
        z-index: 1;
      }

      .point {
        transition: all 0.2s;
        width: @h+2;
        height: @h+2;
        border-radius: 50%;
        background: gray;
        z-index: 2;
        transform: translate(-1rem, 1rem);
      }
    }

    & .move {
      @h: 10rem;

      .bg {
        height: @h;
        background: var(--active-main-bg);
      }

      .progress-line {
        height: @h;
        background: var(--second-text-color);
      }

      .point {
        width: @h+2;
        height: @h+2;
        background: white;
      }
    }

    & .stop {
      @h: 4rem;

      .bg {
        height: @h;
      }

      .progress-line {
        height: @h;
        background: white;
      }

      .point {
        width: @h+2;
        height: @h+2;
        background: white;
      }
    }
  }
}

.living {
  position: absolute;
  left: 50%;
  font-size: 18rem;
  border-radius: 50rem;
  border: 1px solid #e0e0e0;
  padding: 15rem 20rem;
  line-height: 1;
  color: white;
  top: 70%;
  transform: translate(-50%, -50%);
}
</style>
