<template>
  <div>
    <div
      v-if="state.list.length === 0 && isFollow"
      style="
        background-color: #000000;
        height: 100%;
        display: flex;
        width: 100%;
        position: absolute;
        left: 50px;
      "
    >
      <div style="background-color: #000000; height: 100%; display: flex; width: 100%">
        <van-empty description="Chưa có danh sách video nào được yêu thích."> </van-empty>
      </div>
    </div>
    <SlideVerticalInfinite
      ref="listRef"
      v-love="props.uniqueId"
      :id="props.uniqueId"
      :uniqueId="props.uniqueId"
      name="infinite"
      :active="props.active"
      :loading="baseStore.loading"
      v-model:index="state.index"
      :render="render"
      :list="state.list"
      @loadMore="loadMore"
      @refresh="() => getData(true)"
    />
  </div>
</template>

<script setup lang="jsx">
import SlideVerticalInfinite from '@/components/slide/SlideVerticalInfinite.vue'
import { onBeforeUnmount, onMounted, onUnmounted, reactive, ref } from 'vue'
import bus, { EVENT_KEY } from '@/utils/bus'
import { useBaseStore } from '@/store/pinia'
import { slideItemRender } from '@/utils'

const props = defineProps({
  cbs: {
    type: Object,
    default() {
      return {}
    }
  },
  active: {
    type: Boolean,
    default: false
  },
  isFollow: {
    type: Boolean,
    default: false
  },
  resetData: {
    type: Boolean,
    default: false
  },
  api: {
    type: Function,
    default: void 0
  },
  index: {
    type: Number,
    default: 0
  },
  list: {
    type: Array,
    default() {
      return []
    }
  },
  uniqueId: {
    type: String,
    default() {
      return 'uniqueId1'
    }
  }
})

const baseStore = useBaseStore()
const p = {
  onShowComments() {
    console.log('onShowComments')
  }
}

const render = slideItemRender({ ...props.cbs, ...p })
const listRef = ref(null)
const state = reactive({
  index: props.index,
  list: props.list,
  totalSize: 0,
  pageSize: 10
})

function loadMore() {
  if (!baseStore.loading) {
    getData()
  }
}

async function getData(refresh = false) {
  // if (!refresh && state.totalSize === state.list.length) return;
  // if (baseStore.loading) return
  const userData = baseStore.userData
  baseStore.loading = true
  if ((baseStore?.products || []).length > 0) {
    const lists = await baseStore.products
    if (props.isFollow) {
      const filteredUsers = lists.filter((user) => user.loved_users.includes(userData.loginName))
      init(filteredUsers)
    } else {
      init(lists)
    }
  } else {
    const lists = await props.api()
    baseStore.products = lists
    if (props.isFollow) {
      const filteredUsers = lists.filter((user) => user.loved_users.includes(userData.loginName))
      init(filteredUsers)
    } else {
      init(lists)
    }
  }
  baseStore.loading = false
}

const init = (lists) => {
  const newList = lists.flatMap((item, index) =>
    item.video.play_addr.url_list.map((urlItem) => ({
      ...item,
      video: {
        play_addr: {
          url_list: urlItem && urlItem.url ? [urlItem.url] : [],
          poster: urlItem && urlItem.thumbnail ? urlItem.thumbnail : ''
        }
      },
      author: {
        nickname: urlItem && urlItem.author_name ? urlItem.author_name : '',
        avatar_168x168: {
          url_list: urlItem && urlItem.author_logo ? [urlItem.author_logo] : []
        }
      },
      hashtag: urlItem && urlItem.hashtag ? urlItem.hashtag : '',
      desc: urlItem ? `${urlItem.title}${urlItem.desc ? ' - ' + urlItem.desc : ''}` : ''
    }))
  )

  state.list = newList.sort(() => Math.random() - 0.5)
}

// function dislike() {
//   listRef.value.dislike(state.list[1])
//   state.list[state.index] = state.list[1]
//   _notice('操作成功，将减少此类视频的推荐')
// }

// function end() {
//   // _notice('暂时没有更多了')
// }

function click(uniqueId) {
  if (!props.active) return
  if (uniqueId !== props.uniqueId) return
  bus.emit(EVENT_KEY.SINGLE_CLICK_BROADCAST, {
    uniqueId,
    index: state.index,
    id: state.list.length > 0 ? state.list[state.index].project_id : 0,
    type: EVENT_KEY.ITEM_TOGGLE
  })
}

function updateItem({ position, item }) {
  if (position.uniqueId === props.uniqueId) {
    state.list[position.index] = item
  }
}

function togglePlay() {
  if (!props.active) return
  bus.emit(EVENT_KEY.SINGLE_CLICK_BROADCAST, {
    uniqueId: props.uniqueId,
    index: state.index,
    id: state.list[state.index].project_id,
    type: EVENT_KEY.ITEM_TOGGLE
  })
}

function incrementCommentCount(projectId) {
  const project = state.list.find((item) => item.id === projectId)
  if (project) {
    project.statistics.comment_count += 1
  }
}

function toggleLoveCount(data) {
  const project = state.list.find((item) => item.id === data.id)
  if (project) {
    project.statistics.digg_count += data.value
  }
}

onMounted(() => {
  bus.on(EVENT_KEY.SINGLE_CLICK, click)
  bus.on(EVENT_KEY.UPDATE_ITEM, updateItem)
  bus.on(EVENT_KEY.TOGGLE_CURRENT_VIDEO, togglePlay)
  bus.on(EVENT_KEY.INCREMENT_COMMENT, incrementCommentCount)
  bus.on(EVENT_KEY.INCREMENT_LOVE, toggleLoveCount)
})
onUnmounted(() => {
  bus.off(EVENT_KEY.SINGLE_CLICK, click)
  bus.off(EVENT_KEY.UPDATE_ITEM, updateItem)
  bus.off(EVENT_KEY.TOGGLE_CURRENT_VIDEO, togglePlay)
  bus.off(EVENT_KEY.INCREMENT_COMMENT, incrementCommentCount)
  bus.off(EVENT_KEY.INCREMENT_LOVE, toggleLoveCount)
})

onBeforeUnmount(() => {
  if (props.resetData) {
    baseStore.products = []
  }
})
</script>
