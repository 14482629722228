import type { AgencyModel } from '@/models/agency.model'
import type {
  BookingCartModel,
  BookingDraftResponse,
  BookingProjectModel,
  PaymentMethodInfoModel,
  PaymentMethodModel
} from '@/models/booking.model'
import { FField } from '@/models/field.model'
import type {
  ProjectTypeModel,
  RoomDetailModel,
  RoomModel,
  RoomPrimaryModel
} from '@/models/room.model'
import type { UserCmsModel, UserProfileIntro } from '@/models/user-data.model'
import { ssRequest, type ApiResponse } from '@/utils/SSRequest'
import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
const headers = {
  'Content-Type': 'application/json'
}

export async function uploadVideo(folderName: string, file: File): Promise<ApiResponse<any>> {
  const formData = new FormData()
  formData.append('file', file)

  const config: AxiosRequestConfig = {
    url: `/api/v1/nobleapp/UploadVideo?folderName=${encodeURIComponent(folderName)}`,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return ssRequest<any>(config)
}

export async function setUserRegister(data: {
  fullName: string
  phone: string
  email: string
  loginName: string
  verifyType: number
  referral_by: string
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/SetUserRegister',
    method: 'post',
    data,
    headers
  }
  return ssRequest<any>(config)
}

export async function setVerifyCode(data: {
  reg_id: string
  code: string
  secret_cd: string
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/SetVerifyCode',
    method: 'put',
    data,
    headers
  }
  return ssRequest<any>(config)
}

export async function setUserForgetVerifyCode(data: {
  reg_id: string
  code: string
  secret_cd: string
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/SetUserForgetVerifyCode',
    method: 'put',
    data,
    headers
  }
  return ssRequest<any>(config)
}

export async function setResendCode(data: {
  reg_id: string
  phone: string
  email: string
  verifyType: number
  secret_cd: string
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/SetResendCode',
    method: 'put',
    data,
    headers
  }
  return ssRequest<any>(config)
}

export async function setUserPassword(data: {
  userLogin: string
  userPassword: string
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/SetUserPassword',
    method: 'put',
    data,
    headers
  }
  return ssRequest<any>(config)
}

export async function login(data: {
  username: string
  password: string
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/Login',
    method: 'post',
    data,
    headers
  }
  return ssRequest<any>(config)
}

export async function LoginWithToken(data: { token: string }): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/LoginWithToken',
    method: 'post',
    data,
    headers
  }
  return ssRequest<any>(config)
}

export async function compressVideo(file: File): Promise<ApiResponse<any>> {
  const formData = new FormData()
  formData.append('file', file)
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/CompressVideo',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return ssRequest<any>(config)
}

export async function compressImage(file: File): Promise<ApiResponse<any>> {
  const formData = new FormData()

  formData.append('file', file)

  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/CompressImage',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return ssRequest<any>(config)
}

export async function forgotPassword(data: {
  phone: string
  loginName: string
  birthday: string
  idcard_no: string
  verifyType: 0
  udid: string
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/SetUserForgetPassword',
    method: 'post',
    data,
    headers
  }
  return ssRequest<any>(config)
}

export async function setVerificationForgetPasswordCode(data: {
  userId: string
  secret_cd: string
  verificationCode: string
  tokenType: 0
  loginName: string
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/SetVerificationCode',
    method: 'put',
    data,
    headers
  }
  return ssRequest<any>(config)
}

export async function getRoomOpensByProjectCd(projectCd: string): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetRoomOpensByProjectCd?projectCd=' + projectCd,
    method: 'get',
    headers
  }
  return ssRequest<any>(config)
}

export async function getProfileById(): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/GetProfileById',
    method: 'get',
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách Dự án
 *
 * @param openType - Dự án
 * @param typeId - Dự án
 * @returns Lấy danh sách Dự án
 */
export async function getProjectsInApp(params: {
  openType: number
  typeId: number
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetProjectsInApp',
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách Phân khu hoặc tòa theo từng dự án
 *
 * @param projectCd - Dự án
 * @param projectTypeId - Dự án
 * @returns Lấy danh sách Phân khu hoặc tòa theo từng dự án
 */
export async function getBuildingOrSectorByProjectCd(params: {
  projectCd: string
  projectTypeId: number
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetBuildingOrSectorByProjectCd',
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách giá bán
 *
 * @returns Danh sách giá bán
 */
export async function getRoomFilterPrice(): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetRoomFilterPrice',
    method: 'get',
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách diện tích tòa nhà
 *
 * @returns Danh sách diện tích tòa nhà
 */
export async function getRoomFilterArea(): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/getRoomFilterArea',
    method: 'get',
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách diện tích tòa nhà
 *
 * @returns Danh sách diện tích tòa nhà
 */
export async function getProjectType(): Promise<ApiResponse<ProjectTypeModel[]>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetProjectType',
    method: 'get',
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách tầng trong tòa nhà
 *
 * @param buildingCd - Loại mở, dùng để phân loại dự án (ví dụ: 0 hoặc 1).
 * @returns Danh sách tầng thuộc tòa nhà
 */
export async function getFloorByBuildingCd(params: {
  buildingCd: string
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetFloorByBuildingCd',
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách Hướng cửa, Số phòng ngủ
 *
 * @param tableName  Hướng cửa: BLD_RoomDirection, Số PN: BLD_RoomStyle
 * @returns Danh sách tầng thuộc tòa nhà
 */
export async function getRoomFilterObject(params: {
  tableName: string
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetRoomFilterObject',
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách căn hộ
 *
 * @param filter  Tên tìm kiếm
 * @param projectCd  Mã dự án
 * @param projectTypeId  projectTypeId
 * @param buildingCd  Mã tòa
 * @param positionId  Mã vị trí
 * @param directionId  Mã hướngCon
 * @param typeId  Mã loại
 * @param fromFloor  Từ tầng
 * @param toFloor  Đến tầng
 * @param styleNum  Số phòng ngủ
 * @param fromArea  Từ diện tích
 * @param toArea  Đến diện tích
 * @param fromPrice  Từ giá
 * @param toPrice  Đến giá
 * @param floorNum  Số tầng
 * @param openType  openType
 * @returns Lấy danh sách căn hộ
 */
export async function getRoomPrimarys1(params: {
  filter: string
  projectCd: string
  projectTypeId: number
  buildingCd: string
  positionId: string
  directionId: string | number
  typeId: number | string
  fromFloor: number
  toFloor: number
  styleNum: string | number
  fromArea: number
  toArea: number
  fromPrice: number
  toPrice: number
  floorNum: number
  openType: number
}): Promise<ApiResponse<RoomPrimaryModel>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetRoomPrimarys',
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách tầng trong tòa nhà
 *
 * @param buildingCd - Loại mở, dùng để phân loại dự án (ví dụ: 0 hoặc 1).
 * @returns Danh sách tầng thuộc tòa nhà
 */
export async function getRoomDetailInfo(params: {
  roomCd: string
}): Promise<ApiResponse<RoomDetailModel>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetRoomDetailInfo',
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

export async function getProfileFields(loginName): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/GetProfileFields?loginName=' + loginName,
    method: 'get',
    headers
  }
  return ssRequest<any>(config)
}

export async function setProfileFields(data: {
  loginName: string
  fields?: FField[]
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/nobleapp/SetProfileFields',
    method: 'put',
    data,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách căn hộ theo filter lọc điều kiện
 *
 * @param filter  Tên tìm kiếm
 * @param offSet  Số lượng phần tử bỏ qua
 * @param pageSize  Số lượng phần tử trên mỗi trang
 * @param projectCd  Mã dự án
 * @param buildingCd  Mã tòa
 * @param floorNumber  Số tầng
 * @param styleNum  Số phòng ngủ
 * @param directionId  Mã hướng
 * @param lookOutId  Mã tầm nhìn
 * @param statusId  Mã trạng thái
 * @param typeId  Mã loại
 * @returns Danh sách căn hộ
 */
export async function getRoomOpenTypeByProjectCd(params: {
  filter: string
  offSet: number
  pageSize: number
  projectCd: string
  buildingCd: string
  floorNumber: number
  styleNum: string | number
  directionId: string | number
  lookOutId: string | number
  statusId: string | number
  typeId: number | string
}): Promise<ApiResponse<RoomModel[]>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetRoomOpenTypeByProjectCd',
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy thông tin tạm giữ phòng
 *
 * @param params - Đối tượng chứa thông tin bookingId và roomCd
 * @returns Thông tin tạm giữ phòng
 */
export async function getBookingInfoDraft(params: {
  bookingId: string | number
  roomCd: string
}): Promise<ApiResponse<BookingDraftResponse>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetBookingInfoDraft',
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Tạo mới hoặc cập nhật thông tin tạm giữ phòng
 *
 * @param data - Đối tượng chứa thông tin cần thiết để tạo hoặc cập nhật
 * @returns Trạng thái của việc tạo mới hoặc cập nhật
 */
export async function setBookingInfoDraft(data: {
  bookingId: number
  depositAmt: number
  depositAmtStr: string
  custName: string
  phone: string
  idCard_No: string
  bookingDt: string
  totalAmount: number
  projectName: string
  roomCd: string
  roomCode: string
  saleAmount: number
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/SetBookingInfoDraft',
    method: 'post',
    data,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Xác thực thông tin đặt phòng
 *
 * @param data - Đối tượng chứa mã OTP và mã đặt phòng (bookingId)
 * @returns Kết quả xác thực
 */
export async function bookingInfoVerify(data: {
  otpCode: string
  bookingId: number
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/BookingInfoVerify',
    method: 'post',
    data,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy thông tin giỏ hàng trong ứng dụng
 *
 * @param mod_cd - Mã module dùng để lọc giỏ hàng
 * @returns Thông tin giỏ hàng trong ứng dụng
 */
export async function getCartInApp(params: {
  mod_cd: number | string
}): Promise<ApiResponse<BookingCartModel[]>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetCartInApp',
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy phương thức tính giá
 *
 * @param roomCd - Mã phòng
 * @returns Thông tin giỏ hàng trong ứng dụng
 */
export async function getPaymentMethod(params: {
  roomCd: string
}): Promise<ApiResponse<PaymentMethodModel[]>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetPaymentMethod',
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy thông tin tính giá
 *
 * @param roomCd - Mã phòng
 * @returns Thông tin giỏ hàng trong ứng dụng
 */
export async function getPaySchMethodInfo(params: {
  roomCd: string
  paymentMethod: number
}): Promise<ApiResponse<PaymentMethodInfoModel>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetPaySchMethodInfo',
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Tính kì hạn vay ngân hàng
 *
 * @param amount - Số tiền
 * @param period - Kì hạn
 * @param term - Lãi suất
 * @returns Thông tin giỏ hàng trong ứng dụng
 */
export async function getInterestTable(params: {
  amount: number
  period: number
  term: number
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/GetInterestTable',
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Xác thực thông tin đặt phòng
 *
 * @param data - Đối tượng chứa mã OTP và mã đặt phòng (bookingId)
 * @returns Kết quả xác thực
 */
export async function setBookingProject(data: {
  projectCd: string
  projectName: string
  saleAmount: string
  floorNum: string
  mainDoor: string
  bedRoom: string
  agency_id: 0
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/SetBookingProject',
    method: 'post',
    data,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Danh sách đại lí
 *
 * @param projectCd - Mã dự án
 * @returns Danh sách đại lí
 */
export async function getAgencyByProject(projectCd: string): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: `/api/v1/residence/GetAgencyByProject?projectCd=${projectCd}`,
    method: 'get',
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Danh sách booking dự án
 *
 * @returns Danh sách booking dự án
 */
export async function getBookingProjectApp(): Promise<ApiResponse<BookingProjectModel[]>> {
  const config: AxiosRequestConfig = {
    url: `/api/v1/residence/GetBookingProjectApp`,
    method: 'get',
    headers
  }
  return ssRequest<any>(config)
}

export async function guessUploadVideo(
  file: File,
  phoneName: string
): Promise<ApiResponse<string>> {
  const formData = new FormData()
  formData.append('file', file)
  const config: AxiosRequestConfig = {
    url: `/api/v1/nobleapp/GuessUploadVideo?phoneName=${phoneName}`,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return ssRequest<any>(config)
}

/**
 * Follow người dùng
 */
export async function setFollow(data: {
  userIdFollow: string
  userNameFollow: string
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/SetFollow',
    method: 'post',
    data,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách follow
 *
 * @returns Danh sách booking dự án
 */
export async function getFollowsByUserId(): Promise<
  ApiResponse<
    {
      userIdFollow: string
      userNameFollow: string
    }[]
  >
> {
  const config: AxiosRequestConfig = {
    url: `/api/v1/residence/GetFollowsByUserId`,
    method: 'get',
    headers
  }
  return ssRequest<any>(config)
}

export async function getIntroduceByUserId(params: {
  userName: string
}): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: `/api/v1/residence/GetIntroduceByUserId`,
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Set User Introduction Details
 */
export async function setUserIntroduce(data: UserProfileIntro): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/residence/SetUserIntroduce',
    method: 'post',
    data,
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách người dùng với các tham số lọc
 *
 * @param filter - Tham số lọc
 * @param offset - Vị trí bắt đầu
 * @param pageSize - Kích thước trang
 * @returns Danh sách người dùng
 */
export async function getUserPage(
  filter: string,
  offset: number,
  pageSize: number
): Promise<ApiResponse<UserCmsModel[]>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/usercms/GetUserPage',
    method: 'get',
    params: {
      filter,
      offSet: offset,
      pageSize
    },
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Đặt trạng thái người dùng là noble
 *
 * @param regUserId - ID người dùng cần cập nhật
 * @returns Phản hồi từ API
 */
export async function setUserNoble(regUserId: number): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/usercms/SetUserNoble',
    method: 'post',
    data: {
      reg_userId: regUserId
    },
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Lấy danh sách người dùng với các tham số lọc
 *
 * @param filter - Tham số lọc
 * @param offset - Vị trí bắt đầu
 * @param pageSize - Kích thước trang
 * @returns Danh sách người dùng
 */
export async function getAgencyPage(
  filter: string,
  offset: number,
  pageSize: number
): Promise<ApiResponse<AgencyModel[]>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/agency/GetAgencyPage',
    method: 'get',
    params: {
      filter,
      offSet: offset,
      pageSize
    },
    headers
  }
  return ssRequest<any>(config)
}

/**
 * Xóa tổ chức bằng ID
 *
 * @param agencyId - ID của tổ chức cần xóa
 * @returns Phản hồi từ API
 */
export async function deleteAgency(agency_id: number): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: `/v1/agency/DelAgency`,
    method: 'delete',
    params: {
      agency_id
    },
    headers
  }

  return ssRequest<any>(config)
}

export async function setAgency(data: AgencyModel): Promise<ApiResponse<any>> {
  const config: AxiosRequestConfig = {
    url: '/api/v1/agency/SetAgency',
    method: 'post',
    data,
    headers
  }
  return ssRequest<any>(config)
}

export async function getAgencyInfo(params: {
  agency_id: number
}): Promise<ApiResponse<AgencyModel>> {
  const config: AxiosRequestConfig = {
    url: `/api/v1/agency/GetAgencyInfo `,
    method: 'get',
    params,
    headers
  }
  return ssRequest<any>(config)
}
